<template>
  <div class="">
    <v-container>
      <v-layout row wrap justify-space-between class="px-2 mt-2">
        <v-flex xs12 sm6 md4 lg4>
          <h1 class="body-1 font-weight-regular">
            {{ $t("welcome") }},
            <span class="font-weight-bold">{{ fullnames() }}</span>
          </h1>
        </v-flex>
        <v-flex xs12 sm6 md4 lg4>
          <v-dialog
            persistent
            ref="dialogdate"
            v-model="dialogdate"
            :return-value.sync="selectedDate"
            offset-y
            max-width="300"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                outlined
                dense
                :color="$store.state.secondaryColor"
                prepend-inner-icon="mdi-calendar"
                placeholder="Date"
                readonly
                :value="selectedDate"
              ></v-text-field>
            </template>
            <v-date-picker
              :color="$store.state.primaryColor"
              v-model="selectedDate"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                :color="$store.state.primaryColor"
                @click="dialogdate = false"
                >{{ $t("cancel") }}</v-btn
              >

              <v-btn
                text
                :color="$store.state.secondaryColor"
                @click="$refs.dialogdate.save(selectedDate)"
                >{{ $t("ok") }}</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex
          xs12
          sm6
          md4
          lg4
          v-for="(item, i) in display"
          :key="i"
          class="px-1 py-1"
        >
          <v-card
            class="d-flex"
            height="100"
            @click="goTo(item.route, item.windowTitle)"
            elevation="3"
          >
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    class="primary--text caption font-weight-bold text-uppercase"
                    >{{ item.title }}</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle
                  class="black--text font-weight-bold pa-1 title"
                  v-if="item.currency"
                  >Ksh {{ currencyFormat(item.amount) }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  class="black--text font-weight-bold pa-1 title"
                  v-else
                  >Ksh {{ item.amount }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md4 lg4 class="px-1 py-1">
          <v-card
            class="d-flex"
            height="100"
            @click="viewClients()"
            elevation="3"
          >
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    class="primary--text caption font-weight-bold text-uppercase"
                    >{{ $t("clients") }}</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle
                  class="black--text font-weight-bold pa-1 title"
                  >80</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md4 lg4 class="px-1 py-1">
          <v-card class="d-flex" @click="viewLogs()" height="100" elevation="3">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    class="primary--text caption font-weight-bold text-uppercase"
                    >{{ $t("logs") }}</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle
                  class="black--text font-weight-bold pa-1 title"
                  >190</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm6 md6 lg6 class="px-1 py-1">
          <v-card v-if="barChartData.datasets[0].data.length">
            <v-card-title
              class="subtitle-2 text-uppercase font-weight-bold mb-2"
              >{{ $t("daily traffic") }}</v-card-title
            >
            <LineChart
              :chartdata="lineChartData"
              :options="lineChartOptions"
              :style="myStylesLine"
            />
            <v-card-actions class="subtitle-2 font-weight-medium px-4 py-5">
              <v-spacer></v-spacer>
              <span class="primary--text body-1 font-weight-bold text-uppercase"
                >{{ $t("value") }}: </span
              ><br />
              <span
                class="body-1 black--text error--text text--darken-3 red lighten-5 font-weight-bold pa-1 title"
              >
                {{ currencyFormat(currentTraffic) }}</span
              >
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md6 lg6 class="px-1 py-1">
          <v-card v-if="barChartData.datasets[0].data.length">
            <v-card-title
              class="subtitle-2 text-uppercase font-weight-bold mb-1"
            >
              {{ period }} {{ $t("traffic") }} <v-spacer></v-spacer>
              <v-menu offset-y :return-value.sync="period" ref="menuPeriod">
                <template v-slot:activator="{ on }">
                  <input
                    type="text"
                    v-on="on"
                    class="input-style caption"
                    v-model="period"
                  />
                </template>
                <v-list dense class="pa-0">
                  <div v-for="menu in periods" :key="menu">
                    <v-list-item
                      class="caption"
                      @click="$refs.menuPeriod.save(menu)"
                      >{{ menu }}</v-list-item
                    >
                    <v-divider></v-divider>
                  </div>
                </v-list>
              </v-menu>
            </v-card-title>
            <BarChart
              :chartdata="barChartData"
              :options="barChartOptions"
              :styles="myStylesBar"
            />
            <v-card-actions class="subtitle-2 font-weight-medium pa-4">
              <v-spacer></v-spacer>
              <span class="primary--text body-1 font-weight-bold text-uppercase"
                >{{ $t("value") }}: </span
              ><br />
              <span
                class="body-1 black--text error--text text--darken-3 red lighten-5 font-weight-bold pa-1 title"
              >
                {{ currencyFormat(currentTraffic) }}</span
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm6 md6 lg6 class="px-1 py-1">
          <v-card>
            <v-card-title>
              <span class="caption">{{ $t("Top business types") }}</span>
            </v-card-title>
            <DoughnutChart
              :chartdata="doughnutChartData"
              :options="doughnutChartOptions"
            />

            <v-card-actions class="subtitle-2 font-weight-medium px-4 py-5">
              <v-spacer></v-spacer>
              <span class="primary--text body-1 font-weight-bold text-uppercase"
                >{{ $t("value") }}: </span
              ><br />
              <span
                class="body-1 black--text error--text text--darken-3 red lighten-5 font-weight-bold pa-1 title"
              >
                {{ currencyFormat(currentTraffic) }}</span
              >
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md6 lg6 class="px-1 py-1">
          <v-card>
            <v-card-title>
              <span class="caption">{{ $t("Top packages") }}</span>
            </v-card-title>
            <DoughnutChart
              :chartdata="subscriptions"
              :options="doughnutChartOptions"
            />

            <v-card-actions class="subtitle-2 font-weight-medium px-4 py-5">
              <v-spacer></v-spacer>
              <span class="primary--text body-1 font-weight-bold text-uppercase"
                >{{ $t("value") }}: </span
              ><br />
              <span
                class="body-1 black--text error--text text--darken-3 red lighten-5 font-weight-bold pa-1 title"
              >
                {{ currencyFormat(currentTraffic) }}</span
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import db from "@/plugins/fb";
import jwtdecode from "jwt-decode";
import LineChart from "@/components/layout/charts/LineChart.vue";
import BarChart from "@/components/layout/charts/BarChart.vue";
import DoughnutChart from "@/components/layout/charts/DoughnutChart.vue";
import format from "date-fns/format";
// import db from '../../plugins/fb'

export default {
  components: {
    LineChart,
    BarChart,
    DoughnutChart,
  },

  data() {
    return {
      user: "",
      dialogdate: false,
      selectedDate: format(new Date(), "yyyy-MM-dd"),
      currentTraffic: 0,
      currentMonthTraffic: 0,

      doughnutChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        // scales: {
        //   yAxes: [
        //     {
        //       ticks: {
        //         beginAtZero: true,
        //       },
        //       gridLines: {
        //         display: true,
        //       },
        //     },
        //   ],
        //   xAxes: [
        //     {
        //       gridLines: {
        //         display: true,
        //       },
        //     },
        //   ],
        // },
        legend: {
          display: true,
        },
      },

      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        // title: {
        //     display: true,
        //     text: 'Sales per Week',
        // },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
      },

      myStylesLine: {
        height: "250px",
        // backgroundColor: "#038cbe"
      },

      myStylesBar: {
        height: "250px",
        // backgroundColor: "blue",
      },

      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,

        // title: {
        //     display: true,
        //     text: 'Sales per Month',

        // },

        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },

      pieChartData: {
        labels: ["Green", "Red", "Blue"],

        datasets: [
          {
            label: "Data One",
            //   backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
            //  data: [1, 10, 5,50]
          },
        ],
      },

      pieChartOptions: {
        // hoverBorderWidth: 20,
        borderWidth: "10px",
        height: "20px",
        hoverBackgroundColor: "red",
        hoverBorderWidth: "10px",
      },
    };
  },

  computed: {
    currentMonth() {
      return format(new Date(), "MMMM");
    },
    period() {
      return this.$t("Monthly");
    },
    periods() {
      return [this.$t("weekly"), this.$t("monthly"), this.$t("yearly")];
    },

    lineChartData() {
      return {
        labels: ["S", "M", "T", "W", "T", "F", "S"],
        datasets: [
          {
            label: this.$t("weekly sales trend"),
            backgroundColor: "#66BB6A",
            data: [[0, 0, 0]],
          },
        ],
      };
    },
    barChartData() {
      return {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: this.$t("monthly sales"),
            backgroundColor: "#42A5F5",
            data: [0, 0],
          },
        ],
      };
    },
    doughnutChartData() {
      return {
        labels: ["Restaurants", "Shop", "Electronics", "Saloon"],
        datasets: [
          {
            label: this.$t("product trends"),
            backgroundColor: ["#2196F3", "#BBDEFB", "#64B5F6", "#1E88E5"],
            data: [12, 45, 34, 9],
          },
        ],
      };
    },
    subscriptions() {
      return {
        labels: ["Basic", "Standard", "Advanced", "Premium", "Lifetime"],
        datasets: [
          {
            label: this.$t("product trends"),
            backgroundColor: [
              "#2196F3",
              "#BBDEFB",
              "#64B5F6",
              "#1E88E5",
              "#0D47A1",
            ],
            data: [12, 45, 34, 9, 50],
          },
        ],
      };
    },
    display() {
      return [
        {
          title: this.$t("subscription value"),
          route: "Licenses",
          windowTitle: "Licenses",
          amount: 1000,
          number: null,
        },
      ];
    },
  },

  created() {
    this.getUser();
    this.getClients();
    this.getSystemUsers();
  },

  methods: {
    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
    },

    getSystemUsers() {
      db.collection("rootAccess")
        .get()
        .then((snapshot) => {
          this.display[2].amount = snapshot.size;
        });
    },

    fullnames() {
      return `${this.user.names.first} ${this.user.names.middle.substr(
        0,
        1
      )}. ${this.user.names.last}`;
    },

    currencyFormat(val) {
      return this.$store.state.currency.label + " " + val;
    },

    getClients() {
      this.pieChartData.labels = [];
      this.pieChartData.datasets.data = [];

      db.collection("owners")
        .get()
        .then((snapshot) => {
          this.display[3].amount = snapshot.size;
        });

      db.collection("businesses")
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            this.pieChartData.labels.push(snaps.data().businessName);
          });
        });

      // this.$store.state.businesses.forEach(val=>{
      //   this.pieChartData.labels.push(val.businessName);
      // })

      // this.display[3].amount = this.$store.state.businesses.length;
    },

    getIncomeDistribution() {},

    goTo(route, windowTitle) {
      this.$store.commit("SET_WINDOWTITLE", windowTitle);
      this.$router.push({ name: route });
    },
    viewClients() {
      this.$store.commit("SET_WINDOWTITLE", "Clients");
      this.$router.push({ name: "ViewOwner" });
    },
    viewLogs() {
      this.$store.commit("SET_WINDOWTITLE", "Logs");
      this.$router.push({ name: "Logs" });
    },
  },
};
</script>

<style scoped>
.title {
  background-color: aliceblue;
}

.summaryCard {
  margin-top: -10px;
  margin-left: -10px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5%;
  background-color: #5c08ac;
  transition: transform 0.2s;
}

.summaryCard:hover {
  /* padding:50px; */
  transform: scale(1.2);
}
</style>
